export default {
    namespaced: true,
    state:{
        extra:{},
        extras: []
    },
    mutations:{
        setExtra(state,data){
            state.extra=data
        },
        setExtras(state,data){
            state.extras=data
        }
    },
    actions:{
        guardarExtra({commit},data){
            commit('setExtra',data)
        },
        guardarExtras({commit},data){
            commit('setExtras',data)
        },
    }
}