<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="600">
      <v-card>
        <v-card-title class="headline primary">
          TÉRMINOS Y CONDICIONES DE ALQUILER
        </v-card-title>

        <v-card-text>
          <h3 style="padding-top: 10px;">UTILIZACION DEL VEHÍCULO</h3>

          <p>
            1.1. Queda expresamente prohibido, so pena de perder todos los
            derechos
          </p>

          <p>
            1.1.1. Conducir el vehículo personas no autorizadas expresamente por
            el arrendador, constando los datos en el anverso o en su defecto en
            documento adjunto. 1.1.2. Destinar el vehículo al transporte de
            mercancías.
          </p>

          <p>
            1.1.3. Utilizar el vehículo para remolcar o empujar a otros
            vehículos.
          </p>

          <p>
            1.1.4. Participar con el vehículo en carreras, concursos o desafíos.
          </p>

          <p>
            1.1.5. Conducir el vehículo bajo los efectos del alcohol o drogas.
          </p>

          <p>
            1.1.6. Viajar mayor número de personas de las que permite la ley
            para el uso de coche alquilado, o el transporte en servicio público
            de personas.
          </p>

          <p>
            1.1.7. Los vehículos solo pueden circular en la Península Ibérica,
            salvo autorización expresa del arrendador que en su caso se
            reflejara en el anverso.
          </p>

          <p>
            1.2. El arrendatario deberá estar en posesión del correspondiente
            permiso de conducir en vigor y con una antigüedad mínima de un año.
            Al mismo tiempo la edad mínima del arrendatario será de veintitrés,
            veinticinco o veintisiete años, dependiendo del tipo de vehículo
            alquilado.
          </p>

          <p>
            CAUCION En el momento de la firma del contrato el arrendatario
            abonara el importe estimado del alquiler más el importe en su caso
            determinado en la tarifa vigente a modo de caución, importe que será
            devuelto al finalizar el contrato siempre que el mismo no tenga que
            ser utilizado para hacer frente a algún cargo extraordinario
            producido por el arrendatario.
          </p>

          <h3>DEVOLUCION DEL VEHÍCULO</h3>
          <p>
            3.1. El arrendatario devolverá el vehículo alquilado en el lugar y
            la fecha estipulados en el anverso y se compromete a mantener el
            vehículo, su equipo y sus herramientas en perfecto estado de
            funcionamiento. Cualquier alteración debe ser previamente autorizada
            por el arrendador. El incumplimiento de esta condición faculta al
            arrendador a requerir judicialmente la devolución del vehículo y en
            su caso la reclamación en concepto de indemnización del importe de
            cada día de retraso en la entrega del vehículo desde la fecha de
            finalización del contrato según la tarifa aplicada, pudiendo
            reclamar igualmente el arrendador los gastos que suponga la
            recuperación del vehículo fuera del plazo contratado y los danos que
            se le hubieren producido al mismo.
          </p>

          <p>
            3.2. El arrendador está plenamente autorizado para retirar el
            vehículo del arrendatario en cualquier momento, siempre que haya
            transcurrido el plazo contratado para el arrendamiento sin la
            devolución del vehículo.
          </p>

          <h3>CARGOS DEL ALQUILER</h3>
          <p>4.1 El arrendatario se compromete a pagar al arrendador:</p>

          <p>
            4.1.1. Los cargos por el alquiler del vehículo, depósitos, entrega y
            recogida, seguros, combustibles e impuestos determinados en la
            tarifa vigente.
          </p>

          <p>
            4.1.2. El importe de la reparación de los danos causados al
            vehicula, en caso de accidente, cuando concurran las circunstancias
            descritas en el apartado 1 y 5 de estas condiciones generales o
            cuando quede demostrado que los danos o la avería han sido debidos a
            la impericia manifiesta, o el mal trato dado al vehículo por el
            arrendatario y-o conductores autorizados en el contrato.
          </p>

          <p>
            4.1.3. Las multas de tráfico, así como los gastos por infracciones a
            la legislación vigente del código de circulación.
          </p>

          <p>
            4.1.4. El coste de la falta de alguna herramienta, neumáticos u
            otros accesorios del vehículo alquilado. 4.1.5. Los días de
            paralización del vehículo por accidente, avería o retención del
            mismo por organismos públicos, debido a la negligencia del
            arrendatario a razón de la tarifa del tipo de vehículo alquilado.
            4.2. El arrendador aceptará cualquier medio de pago admitido en
            derecho, bien dinero en efectivo, bien tarjeta de crédito, no de
            débito, si bien en caso de que el pago se efectué en efectivo, será
            necesario además la presentación de una tarjeta de crédito como
            garantía, que servirá de aval para el cobro de eventuales cargos
            suplementarios, tales como danos no asegurados, retrasos en la
            devolución o combustible, entre otros.
          </p>

          <h3>SEGURO, RESPONSABILIDAD Y ACCIDENTES</h3>
          <p>
            5.1. El arrendador no podrá responder de las sanciones, multas,
            pleitos incurridos por el arrendatario y-o conductores autorizados,
            así como los gastos judiciales que se deriven. El arrendador queda
            exento de la responsabilidad que surja en el accidente, incluso en
            el caso de que se beba a rotura de piezas mecánicas.
          </p>

          <p>
            5.2. Únicamente los conductores aceptados expresamente por el
            arrendador tienen calidad de asegurados y están cubiertos con un
            seguro obligatorio.
          </p>

          <p>
            5.3. La responsabilidad civil del arrendatario con respecto a los
            danos causados al vehículo arrendado está cubierta totalmente en los
            casos de incendio y robo (previa entrega del juego de llaves), y
            limitado en caso de accidente a una franquicia según la tarifa en
            vigor. El arrendatario queda exento de esta responsabilidad mediante
            el pago de la prima correspondiente según tarifa. Los días de
            paralización del vehículo (apartado 4.5) no están cubiertos por el
            seguro se facturarán aplicándose la tarife vigente para el tipo de
            vehículo alquilado.
          </p>

          <p>
            5.4. En caso de accidente, robo o incendio, incluso parcial, el
            arrendatario se obliga, so pena de perder los derechos del seguro,
            a:
          </p>

          <p>
            5.4.1. Comunicarlo en plazo máximo de 24 horas desde que se produjo
            el mismo.
          </p>

          <p>
            5.4.2. Obtener datos de la parte contraria y posibles testigos
            cumplimentándolo en un parte que remitirá al arrendador dentro del
            plazo de 48 horas desde que se produjo el accidente, haciendo
            constar las circunstancias, lugar, fecha y hora del incidente.
          </p>

          <p>
            5.4.3. Notificar inmediatamente a las autoridades, si la
            culpabilidad de la otra parte debe ser investigada, o si hay
            personas heridas.
          </p>

          <p>5.4.4. No reconocer o prejuzgar la responsabilidad del hecho.</p>

          <p>
            5.4.5. No abandonar el vehículo alquilado sin medidas necesarias
            para protegerle.
          </p>

          <p>5.5. El seguro no cubre:</p>

          <p>5.5.1. Accidentes debidos a la influencia del alcohol o drogas.</p>

          <p>
            5.5.2. Conductores que no están en posesión del permiso de conducir.
          </p>

          <p>
            5.5.3. Pinchazos, rotura (total o parcial) de neumáticos, llantas
            y/o tapa cubos, así como su pérdida o robo.
          </p>

          <p>
            5.5.4. Los danos que sufre el vehículo como consecuencia de
            desniveles o mal estado de la calzada o danos ocasionados por la
            circulación por carreteras no asfaltadas.
          </p>

          <p>
            5.5.5. Los danos ocasionados fuera del periodo de vigencia del
            contrato.
          </p>

          <p>
            5.5.6. Los objetos transportados en el interior del vehículo. El
            arrendador declina todo responsabilidad por accidente o danos a
            propiedades ocasionadas por el vehículo del alquiler si
            deliberadamente el arrendatario ha suministrado al arrendador
            información inexacta relativa a su identidad, domicilio o validez de
            su permiso de conducir.
          </p>

          <p>
            5.5.7. Danos causados en los bajos del vehículo y gastos que
            conlleva.
          </p>

          <p>
            5.5.8. Los gastos de la obtención de un duplicado y/o envió del
            juego de llaves del vehículo, en los casos de perdida, rotura o
            robo. Asimismo, se podrá cargar el precio de alquiler
            correspondiente a los días extras por el coste de paralización del
            vehículo ocasionado en cada caso por cualquiera de las incidencias
            antes mencionadas.
          </p>

          <p>5.5.9. Error al repostar combustible y gastos que conlleva.</p>

          <p>
            5.5.10. Daños ocasionados al embrague por uso inadecuado y gastos
            que conlleva.
          </p>

          <p>5.5.11. Daños a cristales y/o parabrisas.</p>

          <h3>JURISDICCION</h3>
          <p>
            Para cualquier divergencia que pudiera surgir entre el arrendatario
            y el arrendador derivada de la ejecución del presente contrato podrá
            ser sometida bien a los Juzgados y Tribunales del domicilio del
            consumidor o bien a los del lugar donde su hubiera contratado la
            prestación del servicio, a elección del demandante.
          </p>

          <h3>PROTECCION DE DATOS</h3>
          <p>
            En cumplimiento de lo establecido en El Reglamento (UE) 2016/679 del
            Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a
            la protección de las personas físicas en lo que respecta al
            tratamiento de datos personales y a la libre circulación de estos
            datos (RGPD).
          </p>

          <p>
            El Real Decreto-ley 5/2018, de 27 de julio, de medidas urgentes para
            la adaptación del Derecho español a la normativa de la Unión Europea
            en materia de protección de datos. La Ley Orgánica 15/1999, de 13 de
            diciembre, de Protección de Datos de Carácter Personal (LOPD). El
            Real Decreto 1720/2007, de 21 de diciembre, por el que se aprueba el
            Reglamento de desarrollo de la Ley Orgánica 15/1999, de 13 de
            diciembre, de Protección de Datos de Carácter Personal (RDLOPD). La
            Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la
            Información y de Comercio Electrónico (LSSI-CE).
          </p>

          <h3>SE INFORMA:</h3> 
          <p>Que los datos de carácter personal proporcionados a través
          del presente contrato de alquiler quedaran incorporados a un fichero
          para su tratamiento automatizado, con la finalidad de prestar y
          ofrecer nuestros productos, servicios y promociones, concediéndose
          igualmente el consentimiento expreso para el envió de comunicaciones
          comerciales por vía electrónica en los estrictos términos que se
          recogen en las presentes condiciones legales. Este consentimiento,
          podrá revocarlo mediante los mecanismos establecidos para el ejercicio
          de los derechos de acceso, cancelación, rectificación u oposición de
          sus datos de carácter personal. El cliente deberá completar todos los
          campos del contrato con información veraz y actualizada a excepción de
          aquellos que por indicación o naturaleza resulten opcionales. En caso
          de no facilitarse la información necesaria, el arrendador quedara
          facultado para rechazar la solicitud de alquiler. Los datos recogidos
          no serán cedidos a otras personas, empresas u organizaciones.
          Igualmente, deseamos informarle que podrá ejercer los derechos de
          acceso, cancelación, rectificación u oposición a través de los
          siguientes medios: <b>Email: info@libracarrental.com o Personalmente en
          cualquiera de nuestras oficinas.</b></p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            Acepto
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    dialog: Boolean,
  },
};
</script>
