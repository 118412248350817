<template>
    <v-stepper v-model="e1" class="stepper-wrapper">
      <v-stepper-header>
        <v-stepper-step :complete="e1 > 1" step="1">{{$t('steps.header.fechas')}}</v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 2" step="2">{{$t('steps.header.vehiculo')}}</v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 3" step="3">{{$t('steps.header.extras')}}</v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 4" step="4">{{$t('steps.header.datos')}}</v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 5" step="5">{{$t('steps.header.resumen')}}</v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <Form />

          <v-btn color="primary" @click="e1 = 2">
            Continue
          </v-btn>

          <v-btn text href="https://librarentacar.com/">Cancelar</v-btn>
        </v-stepper-content>

        <v-stepper-content step="2">
          <Cars @next="e1 = 3" />

          <v-btn text @click="e1 = 1">Atrás</v-btn>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card class="mb-12" color="lighten-1">
            <Extra @next="e1 = 4" />  
          </v-card>
          <v-btn text @click="e1 = 2">Atrás</v-btn>
        </v-stepper-content>

        <v-stepper-content step="4">
          <v-card class="mb-12" color="lighten-1">
            <Cliente @next="e1 = 5" />  
          </v-card>
          <v-btn text @click="e1 = 3">Atrás</v-btn>
        </v-stepper-content>

        <v-stepper-content step="5">
            <Resumen /> 
            <v-btn text @click="e1 = 4">Atrás</v-btn> 
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Form from "../components/Form";
import Cars from "../components/Cars";
import Extra from "../components/Extra";
import Cliente from "../components/Cliente";
import Resumen from "../components/Resumen";
import moment from 'moment'

export default {
  data() {
    return {
      e1: 2,
    };
  },
  components: {
    Form,
    Cars,
    Extra,
    Cliente,
    Resumen
  },
  methods: {
    ...mapActions("reservaNamespace", [
      "guardarDisponibilidad",
      "guardarCoche",
      "guardarExtras",
      "guardarCliente",
    ]),
  },
  computed: {
    ...mapState("grupoNamespace", ["grupos"]),
    ...mapState("reservaNamespace", [
      "disponibilidad",
      "coche",
      "extras",
      "cliente",
    ]),
  },
  created(){
    moment.locale('es')
  }
};
</script>

<style scoped>
.stepper-wrapper {
  width: 100%;
  margin-top: 128px;
  margin-bottom: auto;
}
</style>
