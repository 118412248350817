import Vue from 'vue'
import Vuex from 'vuex'
import usuarioNamespace from "./modules/usuario"
import clienteNamespace from "./modules/cliente"
import reservaNamespace from "./modules/reserva"
import grupoNamespace from "./modules/grupos"
import extraNamespace from './modules/extra'
import cocheNamespace from './modules/coche'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    usuarioNamespace,
    clienteNamespace,
    reservaNamespace,
    grupoNamespace,
    extraNamespace,
    cocheNamespace
  }
})