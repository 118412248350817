<template>
  <div>
    <h2>Marca</h2>
    <h4>Modelo</h4>
    <v-sheet class="mx-auto" elevation="8" max-width="800">
      <v-slide-group
        v-model="model"
        class="pa-4"
        active-class="success"
        show-arrows
      >
        <v-slide-item
          v-for="n in 15"
          :key="n"
          v-slot:default="{ active, toggle }"
        >
          <v-card
            :color="active ? undefined : 'grey lighten-1'"
            class="ma-4"
            height="200"
            width="200"
            @click="toggle"
          >
            <v-row class="fill-height" align="center" justify="center">
              <v-scale-transition>
                <v-icon
                  v-if="active"
                  color="white"
                  size="48"
                  v-text="'mdi-close-circle-outline'"
                ></v-icon>
              </v-scale-transition>
            </v-row>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </v-sheet>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data: () => ({
    model: null,
  }),
  props: {
      grupo: Number
  },
  created() {
      this.getCoches(this.grupo)
  },
  computed: {
    ...mapState("cocheNamespace", ["coches"]),
  },
  methods: {
      ...mapActions("cocheNamespace", ["getCoches"]),
  }
};
</script>

<style></style>
