import moment from "moment";
import { required } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import DatePicker from "../DatePicker"
/*import Bus from '@/EventBus.js'*/
import swal from "sweetalert";

export default {
  data: () => ({
    modalHoraRecogida: false,
    modalHoraEntrega: false,
    hora_recogida: null,
    hora_entrega: null,
    menu1: false,
    menu2: false,
    oficinaRecogida: "",
    oficinaDevolucion: "",
    edad: null,
    codigo: "",
    date_desde: new Date().toISOString().substr(0, 10),
    date_hasta: new Date().toISOString().substr(0, 10),
    desde: "",
    hasta: "",
    dateFormattedDesde: null,
    dateFormattedHasta: null,
    horariosArr: [
      { text: "00:00", value: "00:00" },
      { text: "00:30", value: "00:30" },
      { text: "01:00", value: "01:00" },
      { text: "01:30", value: "01:30" },
      { text: "02:00", value: "02:00" },
      { text: "02:30", value: "02:30" },
      { text: "03:00", value: "03:00" },
      { text: "03:30", value: "03:30" },
      { text: "04:00", value: "04:00" },
      { text: "04:30", value: "04:30" },
      { text: "05:00", value: "05:00" },
      { text: "05:30", value: "05:30" },
      { text: "06:00", value: "06:00" },
      { text: "06:30", value: "06:30" },
      { text: "07:00", value: "07:00" },
      { text: "07:30", value: "07:30" },
      { text: "08:00", value: "08:00" },
      { text: "08:30", value: "08:30" },
      { text: "09:00", value: "09:00" },
      { text: "09:30", value: "09:30" },
      { text: "10:00", value: "10:00" },
      { text: "10:30", value: "10:30" },
      { text: "11:00", value: "11:00" },
      { text: "11:30", value: "11:30" },
      { text: "12:00", value: "12:00" },
      { text: "12:30", value: "12:30" },
      { text: "13:00", value: "13:00" },
      { text: "13:30", value: "13:30" },
      { text: "14:00", value: "14:00" },
      { text: "14:30", value: "14:30" },
      { text: "15:00", value: "15:00" },
      { text: "15:30", value: "15:30" },
      { text: "16:00", value: "16:00" },
      { text: "16:30", value: "16:30" },
      { text: "17:00", value: "17:00" },
      { text: "17:30", value: "17:30" },
      { text: "18:00", value: "18:00" },
      { text: "18:30", value: "18:30" },
      { text: "19:00", value: "19:00" },
      { text: "19:30", value: "19:30" },
      { text: "20:00", value: "20:00" },
      { text: "20:30", value: "20:30" },
      { text: "21:00", value: "21:00" },
      { text: "21:30", value: "21:30" },
      { text: "22:00", value: "22:00" },
      { text: "22:30", value: "22:30" },
      { text: "23:00", value: "23:00" },
      { text: "23:30", value: "23:30" },
    ],
    oficinasGet: [
      { text: "Oficina central", value: 1 },
      { text: "Aeropuerto", value: 2 },
    ],
    edades: [],
    today: moment(new Date()).format("YYYY-MM-DD"),
    dateAt: moment(new Date()).format("YYYY-MM-DD"),
    dateTo: moment(new Date()).format("YYYY-MM-DD"),
    primer_time: null,
  }),
  components: {
    DatePicker
  },
  props: {
    oficinas: Array,
  },
  beforeCreate() {
    moment.locale("es");
  },
  mounted: function() {
    this.selectEdadesFn();
  },
  validations: {
    date_desde: { required },
    date_hasta: { required },
    oficinaRecogida: { required },
    hora_recogida: { required },
    oficinaDevolucion: { required },
    hora_entrega: { required },
    edad: { required },
  },
  computed: {
    IsSafari() {
      var is_safari = navigator.userAgent.toLowerCase().indexOf("safari/") > -1;
      return is_safari;
    },
    recogida() {
      return this.$t("form.recogida");
    },
    devolucion() {
      return this.$t("form.devolucion");
    },
    selectOficinaTitle() {
      this.$t("form.selectOficina");
    },
    horarioRecogidaErrors() {
      const errors = [];
      if (!this.$v.hora_recogida.$dirty) return errors;
      // !this.$v.name.decimal && errors.push('Name must be a number')
      !this.$v.hora_recogida.required &&
        errors.push(this.$t("form.horarioRequerido"));
      return errors;
    },
    horarioEntregaErrors() {
      const errors = [];
      if (!this.$v.hora_entrega.$dirty) return errors;
      // !this.$v.name.decimal && errors.push('Name must be a number')
      !this.$v.hora_entrega.required &&
        errors.push(this.$t("form.horarioRequerido"));
      return errors;
    },
    oficinaRecogidaErrors() {
      const errors = [];
      if (!this.$v.oficinaRecogida.$dirty) return errors;
      !this.$v.oficinaRecogida.required &&
        errors.push(this.$t("form.itemRequerido"));
      return errors;
    },
    oficinaDevolucionErrors() {
      const errors = [];
      if (!this.$v.oficinaDevolucion.$dirty) return errors;
      !this.$v.oficinaDevolucion.required &&
        errors.push(this.$t("form.itemRequerido"));
      return errors;
    },
    desdeErrors() {
      const errors = [];
      let hoy = new Date();
      hoy = moment(hoy).format("Y-MM-DD");
      if (!this.$v.date_desde.$dirty) return errors;
      this.$v.date_desde.$model < hoy && errors.push(this.$t("form.desdeErr"));
      !this.$v.date_desde.required && errors.push(this.$t("form.desde"));
      return errors;
    },
    hastaErrors() {
      const errors = [];
      if (!this.$v.date_hasta.$dirty) return errors;
      this.$v.date_hasta.$model < this.$v.date_desde.$model &&
        errors.push(this.$t("form.hastaErr"));
      !this.$v.date_hasta.required && errors.push(this.$t("form.hasta"));
      return errors;
    },
    edadErrors() {
      const errors = [];
      if (!this.$v.edad.$dirty) return errors;
      !this.$v.edad.required && errors.push(this.$t("form.itemRequerido"));
      return errors;
    },
  },
  methods: {
    /* eslint-disable no-debugger */
    ...mapActions("reservaNamespace", ["guardarDisponibilidad"]),
    selectEdadesFn() {
      let me = this;
      for (let i = 25; i >= 25 && i <= 85; i++) {
        me.edades.push(i);
      }
    },
    async changeDateDesde($event) {
      debugger
      let hoy = moment(new Date()).format("YYYY-MM-DD");
      await this.dateAt != hoy
      this.dateFormattedDesde = $event ? moment($event).format("L") : moment(this.dateAt).format("L")
      this.desde = moment(this.dateFormattedDesde, "L").format();
    },
    async changeDateHasta($event) {
      let hoy = moment(new Date()).format("YYYY-MM-DD");
      await this.dateTo != hoy
      this.dateFormattedHasta = $event ? moment($event).format("L") : moment(this.dateTo).format("L")
      this.hasta = moment(this.dateFormattedHasta, "L").format();
    },
    guardar() {
      this.guardarDisponibilidad({
        date_desde: this.desde,
        date_hasta: this.hasta,
        hora_recogida: this.hora_recogida,
        hora_entrega: this.hora_entrega,
        oficinaRecogida: this.oficinaRecogida,
        oficinaDevolucion: this.oficinaDevolucion,
        edad: this.edad,
        codigo: this.codigo,
      });

      this.clear();
    },
    clear() {
      this.$v.$reset();
      this.codigo = "";
      this.date_desde = "";
      this.date_hasta = "";
      this.edad = null;
      this.hora_recogida = null;
      this.hora_entrega = null;
      this.oficinaRecogida = null;
      this.oficinaDevolucion = null;
      this.dateFormattedDesde = null;
      this.dateFormattedHasta = null;
    },
  },
  watch: {
    /* eslint-disable no-debugger */
    date_desde: function(date) {
      this.dateAt = date;
      this.date_hasta = null;
      this.dateFormattedHasta = null;
      this.hora_recogida = "";
    },
    date_hasta: function(date) {
      this.dateTo = date;
      this.hora_entrega = "";
    },
    hora_recogida: function(time1) {
      debugger;
      let new_time = new Date();
      let hoy = moment(new_time).format("YYYY-MM-DD");
      let next_day = moment(new_time)
        .add(1, "day")
        .format("YYYY-MM-DD");

      let hora = new Date(this.date_desde + " " + time1);
      if (
        moment(hoy).isSame(this.date_desde) ||
        moment(next_day).isSame(this.date_desde)
      ) {
        let horamas12 = moment(new_time).add(12, "hour");

        if (moment(hora).isBefore(horamas12)) {
          swal({
            title: `ERROR!`,
            text: this.$t("form.swal_error_actual"),
            icon: "info",
          });
          this.hora_recogida = "";
        } else {
          this.primer_time = hora;
        }
      }
    },
    hora_entrega: function(time2) {
      debugger;
      let hora = new Date(this.date_hasta + " " + time2);
      if (moment(hora).isBefore(this.primer_time)) {
        swal({
          title: `ERROR!`,
          text: this.$t("form.swal_error_recogida"),
          icon: "info",
        });
        this.hora_entrega = "";
      }
    },
  },
};
