import axios from "axios";
export default {
    namespaced: true,
    state:{
        coche:{},
        plan:{},
        francodepos:{},
        days:0
    },
    mutations:{
        setCoches(state, coches) {
            state.coches = coches;
          },
        setData(state,payload){
            state.coche=payload.coche
            state.days=payload.date
            state.plan=payload.plan
            state.francodepos=payload.francodepos
        }
    },
    actions:{
      /* eslint-disable no-debugger */
        getCoches: async function({ commit }, data) {
            // let header = { Token: token };
            // let configuracion = { headers: header };
      
            await axios
              .get(`coche/listGroup?id=${data.id}`)
              .then(function(response) {
                commit("setCoches", response.data);
              })
              .catch(function(error) {
                console.log(error);
              });
          },
        guardarData({commit},payload){
            debugger
            commit('setData',payload)
        }
        /* eslint-disable no-debugger */
    }
}