<template>
  <v-form>
    <v-container>
      <v-row>
        <v-col cols="12" md="6">
            <label>Date</label>
        <v-input cols="12" md="6" type="date"></v-input>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
export default {};
</script>

<style>
input[type="date"]
{
    display:block;
  
    /* Solution 1 */
     -webkit-appearance: textfield;
    -moz-appearance: textfield;
    min-height: 1.2em; 
  
    /* Solution 2 */
    /* min-width: 96%; */
}
</style>
