<template>
  <v-app-bar
    :clipped-left="$vuetify.breakpoint.lgAndUp"
    app
    color="blue-grey darken-4"
    prominent
    dark
    absolute
  >
    <v-row style="max-width: 100% !important">
      <v-col cols="6" md="6">
        <a href="https://librarentacar.com/">
          <v-img
            alt="Vuetify Logo"
            contain
            src="../../assets/logo.png"
            transition="scale-transition"
            width="400"
            id="logo"
          />
        </a>
      </v-col>
      <v-col cols="6" md="6">
        <LocaleSelect class="flags" />
      </v-col>
    </v-row>
  </v-app-bar>
</template>
<script>
import LocaleSelect from '../LocaleSelect'
export default {
  name: "Header",
  data() {
    return {
      search: "",
      drawer: false,
    };
  },
  components: {
    LocaleSelect
  },
  computed: {},
  methods: {
    salir() {
      this.$store.dispatch("salir");
    },
  },
};
</script>

<style lang="scss">
#logo {
  margin: 20px;
}
.flags {
  padding-top: 30px;
  align-content: center;
}
@media (max-width: 640px) {
  
}

@media (max-width: 959px) {
  #logo {
    width: none;
  }
  .flags {
    padding-top: 0px;
  }
}
</style>
