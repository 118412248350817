<template>
  <v-container id="signinup-form" class="fill-height">
    <v-row align="center" justify="center" no-gutters>
      <v-col cols="12" sm="8" md="8" class>
        <v-card class="evelation-12 card">
          <v-window v-model="step">
            <!--LOGIN-->
            <v-window-item :value="1">
              <v-row class>
                <v-col cols="12" md="8" class="pt-6 pb-6">
                  <v-card-text>
                    <v-form
                      v-model="valid"
                      class="signup-form-form"
                      @submit.prevent="ingresar"
                    >
                      <h1
                        class="text-center display-1 mb-10"
                        :class="`${bgColor}--text`"
                      >
                        Iniciar sesión
                      </h1>
                      <v-text-field
                        id="email"
                        v-model="email"
                        label="eMail"
                        name="email"
                        append-icon="email"
                        type="email"
                        :rules="emailRules"
                      />
                      <v-text-field
                        id="password"
                        v-model="password"
                        label="Password"
                        name="Password"
                        append-icon="lock"
                        type="password"
                        :color="bgColor"
                        :rules="passwordRules"
                      />
                      <div class="text-center">
                        <a
                          href="#"
                          class="mt-3 overline no-text-decoration"
                          :class="`${bgColor}--text`"
                          @click="step = 3"
                          >Olvidaste tu contraseña?</a
                        >
                      </div>
                      <div class="text-center mt-6">
                        <v-btn type="submit" large :color="bgColor" dark
                          >Ingresar</v-btn
                        >
                      </div>
                    </v-form>
                  </v-card-text>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  class="darken-2 vcenter"
                  :class="`${bgColor}`"
                >
                  <div>
                    <v-card-text :class="`${fgColor}--text`">
                      <h1 class="text-center headline mb-3">
                        No eres usuario?
                      </h1>
                      <h5 class="text-center overline mb-3">
                        Por favor, registrate para continuar
                      </h5>
                    </v-card-text>
                    <div class="text-center mb-6">
                      <v-btn dark outlined @click="step = 2">Registrarse</v-btn>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-window-item>
            <!--REGISTRO-->
            <v-window-item :value="2">
              <v-row class="fill-height">
                <v-col
                  cols="12"
                  md="4"
                  class="darken-2 vcenter"
                  :class="`${bgColor}`"
                >
                  <div>
                    <v-card-text :class="`${fgColor}--text`">
                      <h1 class="text-center headline mb-3">
                        Ya eres usuario?
                      </h1>
                      <h5 class="text-center overline mb-3">
                        Por favor, inicia sesión
                      </h5>
                    </v-card-text>
                    <div class="text-center mb-6">
                      <v-btn dark outlined @click="step = 1">Iniciar</v-btn>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" md="8" class="pt-6 pb-6">
                  <v-card-text>
                    <h1
                      class="text-center display-1 mb-10"
                      :class="`${bgColor}--text`"
                    >
                      Registro de usuario
                    </h1>
                    <v-form
                      class="signup-form-form"
                      v-model="valid"
                      @submit.prevent="registrarse"
                    >
                      <v-text-field
                        id="nombre"
                        v-model="nombre"
                        label="Nombre"
                        name="username"
                        append-icon="person"
                        type="text"
                        required
                        :rules="nameRules"
                      />
                      <v-text-field
                        id="apellido"
                        v-model="apellido"
                        label="Apellido"
                        name="username"
                        append-icon="person"
                        type="text"
                        required
                        :rules="surnameRules"
                      />
                      <v-text-field
                        id="email"
                        v-model="email"
                        label="Correo electrónico"
                        name="email"
                        append-icon="email"
                        type="email"
                        required
                        :rules="emailRules"
                      />
                      <v-text-field
                        id="password"
                        v-model="password"
                        label="Contraseña"
                        name="password"
                        append-icon="lock"
                        type="password"
                        required
                      />
                      <div class="text-center mt-6">
                        <v-btn type="submit" large :color="bgColor" dark
                          >Registrarse</v-btn
                        >
                      </div>
                    </v-form>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-window-item>
            <!--RECUPERAR CONTRASEÑA-->
            <v-window-item :value="3">
              <v-row class="fill-height">
                <v-col
                  cols="12"
                  md="4"
                  class="darken-2 vcenter"
                  :class="`${bgColor}`"
                >
                  <div>
                    <v-card-text :class="`${fgColor}--text`">
                      <h1 class="text-center headline mb-3">
                        ¿No eres usuario?
                      </h1>
                      <h5 class="text-center overline mb-3">
                        Por favor, registrate
                      </h5>
                    </v-card-text>
                    <div class="text-center mb-6">
                      <v-btn dark outlined @click="step = 2">Registrarse</v-btn>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" md="8" class="pt-6 pb-6">
                  <v-card-text>
                    <v-form class="signup-form-form">
                      <h1
                        class="text-center display-1 mb-10"
                        :class="`${bgColor}--text`"
                      >
                        Reestablecer contraseña
                      </h1>
                      <v-text-field
                        id="login"
                        v-model="email"
                        label="eMail"
                        name="login"
                        append-icon="person / email"
                        type="text"
                        :color="bgColor"
                        class="v-input__icon--double"
                      />
                      <div class="text-center mt-6">
                        <v-btn large :color="bgColor" dark @click="reestablecer"
                          >Reestablecer
                        </v-btn>
                      </div>
                    </v-form>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-window-item>
          </v-window>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
import {mapActions, mapState} from 'vuex';
import swal from "sweetalert";
export default {
  props: {
    source: {
      type: String,
      default: "",
    },
    bgColor: {
      type: String,
      default: "primary",
    },
    fgColor: {
      type: String,
      default: "white",
    },
  },
  data: () => ({
    valid: false,
    step: 1,
    nombre: "",
    apellido: "",
    email: "",
    password: "",
    login: "",
    nameRules: [
      (v) => !!v || "El Nombre es requerido",
      (v) => v.length <= 30 || "El nombre debe tener menos de 15 caracteres",
    ],
    surnameRules: [
      (v) => !!v || "El Apellido es requerido",
      (v) => v.length <= 40 || "El apellido debe tener menos de 15 caracteres",
    ],
    emailRules: [
      (v) => !!v || "E-mail es requerido",
      (v) => /.+@.+/.test(v) || "El E-mail no es valido",
    ],
    passwordRules: [(v) => !!v || "La contraseña es requerida"],
  }),
  computed: {
    ...mapState("clienteNamespace",["cliente"]),
  },
  methods: {
    /* eslint-disable no-debugger */
    ...mapActions("clienteNamespace",["guardarToken"]),
    limpiar() {
      this.nombre = "";
      this.apellido = "";
      this.email = "";
      this.password = "";
    },
    registrarse() {
      let me = this;
      axios
        .post("cliente/registration", {
          nombre: this.nombre,
          apellido: this.apellido,
          email: this.email,
          password: this.password,
        })
        .then(function(response) {
          swal({
            title: `Buen trabajo ${response.data.nombre}!`,
            text: "Usuario creado correctamente",
            icon: "success",
          });
          me.limpiar();
          me.step = 1;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    ingresar() {
      axios
        .post("cliente/login", { email: this.email, contrasena: this.password })
        .then((respuesta) => {
          return respuesta.data;
        })
        .then((data) => {
          swal({
            title: "Genial!",
            text: "Bienvenido al sistema",
            icon: "success",
          });
          this.guardarToken(data.tokenReturn);
          let Tipo = this.cliente.tipo;
          switch (Tipo) {
            case "Particular":
              this.$router.push({ name: "reserva" });
              break;
            case "Empresa":
              this.$router.push({ name: "reserva" });
              break;
          }
        })
        .catch((error) => {
          //console.log(error);
          this.errorM = null;
          if (error.response == 404) {
            this.errorM =
              "No exíste el usuario o las credenciales son incorrectas";
          } else {
            this.errorM = "Ocurrió un error con el servidor";
          }
        });
    },
    reestablecer() {
      axios
        .post("email/reset", { email: this.email })
        .then((respuesta) => {
          return respuesta.data;
        })
        .then((data) => {
          console.log(data);
          swal({
            title: "Genial!",
            text: "Recibirás un email con tun nueva contraseña",
            icon: "success",
          });
        })
        .catch((error) => {
          //console.log(error);
          this.errorM = null;
          if (error.response == 404) {
            this.errorM = "No exíste un usuario con este email";
          } else {
            this.errorM = "Ocurrió un error con el servidor";
          }
        });
    },
    /* eslint-disable no-debugger */
  },
};
</script>

<style scoped lang="scss">
.v-input__icon--double .v-input__icon {
  margin-left: -4.25rem !important;
}
a.no-text-decoration {
  text-decoration: none;
}
#signinup-form {
  max-width: 75rem;
}
.signup-form-form {
  max-width: 23rem;
  margin: 0 auto;
}
.card {
  overflow: hidden;
}
.vcenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
