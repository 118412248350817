import axios from "axios";
import Bus from "@/EventBus";
import { mapActions } from "vuex";

export default {
  name: "extra",
  data() {
    return {
      plan: {},
      precio_plan: 0,
      date: null,
      datos_vehiculo: {},
      precios: [],
      tipo: null,
      valor: 0,
      cantidad: 0,
      total: 0,
    };
  },
  methods: {
    ...mapActions("extraNamespace", ["guardarExtra"]),
    ...mapActions("reservaNamespace", ["tarifa"]),
    tipoFn(tipo) {
      if (tipo == 1) {
        return this.$t("extra.franquicia");
      } else {
        return this.$t("extra.deposito");
      }
    },
    languageFn(id) {
      if (id == 1) {
        return this.$t("extra.asiento_baby");
      } else if (id == 2) {
        return this.$t("extra.asiento_bo");
      } else if (id == 3) {
        return this.$t("extra.aeropuerto");
      } else if (id == 4) {
        return this.$t("extra.gps");
      } else if (id == 5) {
        return this.$t("extra.driver");
      } else if (id == 6) {
        return this.$t("extra.noche");
      }
    },
    getSelectVehicle() {
      this.plan = JSON.parse(localStorage.getItem("plan"));
      this.date = JSON.parse(localStorage.getItem("date"));
      this.tipo = localStorage.getItem("tipo");
      this.valor = localStorage.getItem("valor");
      this.datos_vehiculo = JSON.parse(localStorage.getItem("datos_vehiculo"));
      this.precio_plan = this.plan.monto;
      this.tarifa(this.plan.monto);
      this.$forceUpdate();
    },
    getExtras() {
      axios
        .get(`fecha-temporadas/extra`)
        .then((resp) => {
          this.precios = resp.data.data;
          this.precios.forEach((element) => {
            element.cantidad = this.cantidad;
          });
          this.total = this.plan.monto;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    sum(id) {
      /* eslint-disable no-debugger */
      let suma = 0;
      this.precios.forEach((element) => {
        if (element.id == id) {
          element.cantidad = element.cantidad + 1;
          suma = suma + element.precio;
        }
      });
      this.plan.monto = this.plan.monto + suma;
      this.$forceUpdate();
    },

    sub(id) {
      let rest = 0;
      this.precios.forEach((element) => {
        if (element.id == id) {
          if (element.cantidad > 0) {
            element.cantidad = element.cantidad - 1;
            rest = rest + element.precio;
          }
        }
      });
      this.plan.monto = this.plan.monto - rest;
      this.$forceUpdate();
    },
    nextStep() {
      this.guardarExtra(this.precios);
      this.$emit("next");
    },
  },
  created() {
    this.getSelectVehicle();
    this.getExtras();
    this.$forceUpdate();
  },
  mounted() {
    Bus.$on("update", () => {
      this.getSelectVehicle();
      this.$forceUpdate();
    });
  },
};
