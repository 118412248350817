import { required, email } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import Bus from '@/EventBus'

export default {
  data: () => ({
    tipo_cliente: "",
    nombre: "",
    apellido: "",
    email: "",
    emailConfirm: "",
    telefono_movil: "",
    observaciones: "",
    suscripcion: false,
    terminos: true,
    tipoclienteArr: ["Particular", "Empresa"],
  }),
  validations: {
    tipo_cliente: { required },
    nombre: { required },
    apellido: { required },
    email: { required, email },
    emailConfirm: { required, email },
    telefono_movil: { required },
    terminos: { required },
  },
  computed: {
    tipoClienteErrors() {
      const errors = [];
      if (!this.$v.tipo_cliente.$dirty) return errors;
      // !this.$v.name.decimal && errors.push('Name must be a number')
      !this.$v.tipo_cliente.required &&
        errors.push("Debes seleccionar el tipo de cliente");
      return errors;
    },
    nombreErrors() {
      const errors = [];
      if (!this.$v.nombre.$dirty) return errors;
      // !this.$v.name.decimal && errors.push('Name must be a number')
      !this.$v.nombre.required && errors.push("Tu nombre es requerido");
      return errors;
    },
    apellidoErrors() {
      const errors = [];
      if (!this.$v.apellido.$dirty) return errors;
      !this.$v.apellido.required && errors.push("Tu apellido es requerido");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("El email no es válido");
      !this.$v.email.required &&  errors.push("El email es requerido");
      return errors;
    },
    confirmEmailErrors() {
      const errors = [];
      if (!this.$v.emailConfirm.$dirty) return errors;
      !this.$v.emailConfirm.email && errors.push("El email no es válido");
      !this.$v.emailConfirm.required && errors.push("El email es requerido");
      this.$v.email.$model != this.$v.emailConfirm.$model &&
        errors.push("El email no coincide");
      return errors;
    },
    telefonoErrors() {
      const errors = [];
      if (!this.$v.telefono_movil.$dirty) return errors;
      !this.$v.telefono_movil.required &&
        errors.push("Debes ingresar un número de móvil");
      return errors;
    },
    terminosErrors() {
      const errors = [];
      if (!this.$v.terminos.$dirty) return errors;
      // !this.$v.name.decimal && errors.push('Name must be a number')
      !this.$v.terminos.$model && errors.push("Debes aceptar los términos y condiciones");
      return errors;
    },
  },
  methods: {
      /* eslint-disable no-debugger */
    ...mapActions("reservaNamespace", ["guardarCliente"]),
    guardar() {
      
        debugger
      this.guardarCliente({
        tipo_cliente: this.tipo_cliente,
        nombre: this.nombre,
        apellido: this.apellido,
        email: this.email,
        telefono:this.telefono_movil,
        observaciones:this.observaciones
      });
      Bus.$emit('updateResumen')
      this.$emit("next");
    },
    /* eslint-disable no-debugger */
  },
};
