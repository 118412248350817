<template>
  <Form />
</template>

<script>
import Form from '../components/Form'
export default {
  data: () => ({
  }),
  components: {
    Form
  }
};
</script>
