import { mapActions, mapState } from "vuex";
import Bus from '@/EventBus'
import moment from 'moment'

export default {
  data: () => ({
    panel: [0, 1],
    readonly: false,
    total: 0,
    date_reserva: {},
    ofi1: null,
    ofi2: null,
    date1: null,
    date2: null,
    hoursPickup: null,
    hoursReturn: null
  }),
  computed: {
    ...mapState("reservaNamespace", ["cliente", "pagoOnline", "tarifa"]),
    ...mapState("extraNamespace", ["extra"]),
    ...mapState("cocheNamespace", ['coche', 'plan', 'days', 'francodepos'])
  },
  methods: {
    /* eslint-disable no-debugger */
    ...mapActions("reservaNamespace", ["saveReserva"]),
    guardar() {
      debugger
      this.saveReserva({
        cocheId: this.coche.id,
        cocheMarca: this.coche.marca,
        cocheModelo: this.coche.modelo,
        cocheMatricula: this.coche.matricula,
        grupo: this.coche.groupName,
        clienteId: this.cliente.id,
        clienteName: this.cliente.nombre,
        clienteSurname: this.cliente.apellido,
        telefono: this.cliente.telefono_movil,
        edadConductor: this.date_reserva.edad,
        email: this.cliente.email,
        observaciones: this.cliente.observaciones,
        francodepos: this.francodepos,
        tarifa: this.tarifa,
        total: this.total,
        fecha_recogida: this.date1,
        fecha_entrega: this.date2,
        hora_entrega: this.date_reserva.hora_entrega,
        hora_recogida: this.date_reserva.hora_recogida,
        lugar_entrega: this.ofi1,
        lugar_recogida: this.ofi2,
        extra: this.extra,
      });
    },
    /* eslint-disable no-debugger */
    calular() {
      debugger
      this.date_reserva = JSON.parse(localStorage.getItem("datos_reserva"));
      this.total = this.plan.monto
      this.ofi1 = this.date_reserva.oficinaRecogida == 1 ? 'Oficina central' : 'Aeropuerto'
      this.ofi2 = this.date_reserva.oficinaDevolucion == 1 ? 'Oficina central' : 'Aeropuerto'
      this.date1 = moment(this.date_reserva.date_desde).format('LL')
      this.date2 = moment(this.date_reserva.date_hasta).format('LL')
      this.hoursPickup = this.date_reserva.hora_entrega;
      this.hoursReturn = this.date_reserva.hora_recogida;

      for (let index = 0; index < this.extra.length; index++) {
        if (this.extra[index].cantidad) {
          let subtotal = this.extra[index].precio * this.extra[index].cantidad

          this.total = this.total + subtotal
        }

      }

    }
  },
  async created() {
    moment.locale('es')

  },
  mounted() {
    Bus.$on('updateResumen', async () => {
      await this.extra
      this.cliente
      this.coche
      this.plan
      this.days
      this.calular()
    })
  },
};
