<template>
  <v-app>
    <v-main style="padding: 0px;">
      <router-view name="header" />
      <v-container fluid fill-height>
        <v-slide-y-transition mode="out-in">
          
          <router-view />
          
        </v-slide-y-transition>
      </v-container>
      <router-view name="footer" />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
    };
  },
};
</script>

<style>
  .v-main__wrap{
    position: absolute !important;
  }
</style>