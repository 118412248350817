<template>
    <div>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad id perspiciatis ullam natus velit libero, quam non sunt dolor nesciunt quisquam sit corporis a minima iusto eaque. Illum, dicta culpa!
    </div>
</template>
<script>

import CryptoJS from "crypto-js";
//import SHA256  from "crypto-js/sha256";
import axios from 'axios'

export default {
    created() {
        let c={
            "DS_MERCHANT_AMOUNT": "145",
            "DS_MERCHANT_CURRENCY": "978",
            "DS_MERCHANT_CVV2": "123",
            "DS_MERCHANT_EXPIRYDATE": "1512",
            "DS_MERCHANT_MERCHANTCODE": "999008881",
            "DS_MERCHANT_ORDER": "1446068581",
            "DS_MERCHANT_PAN": "454881********04",
            "DS_MERCHANT_TERMINAL": "1",
            "DS_MERCHANT_TRANSACTIONTYPE": "0"
        }
        let a = CryptoJS.HmacSHA256("esta-es-la-prueba-de-encriptar",JSON.stringify(c));
        let b = a.toString(CryptoJS.enc.Base64);
        console.log( btoa(a) )
        console.log(b)

        axios.post(`https://sis-t.redsys.es:25443/sis/rest/trataPeticionREST`,{
            "Ds_MerchantParameters":btoa(a),
            "Ds_Signature":"ktRNde3EYVjTEXGk5lZ70MDieUB3sAPx",
            "Ds_SignatureVersion":"HMAC_SHA256_V1"
        })
        .then( resp => {
            console.log( resp )
        })
        .catch( err => console.log( err ))
    },
}
</script>