<template>
  <div>
    <button
      v-for="locale in locales"
      :key="locale.key"
      @click="changeLocale(locale.key)"
      class="btn"
    >
      <flag :iso="locale.flag" v-bind:squared="false" /> {{ locale.label }}
    </button>
  </div>
</template>

<script>
export default {
  data: () => ({
    locales: [
      { key: "es", flag: "es", label: "Español" },
      { key: "en", flag: "gb", label: "English" },
    ],
  }),
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale;
    },
  },
};
</script>

<style>
.btn {
    margin: 5px;
}
</style>
