
import axios from "axios";
// import moment from "moment";

export default {
  namespaced: true,
  state: {
    grupos: [],
  },
  mutations: {
    setGrupos(state, coche) {
      state.grupos = coche;
    },
  },
  actions: {
    /* eslint-disable no-debugger */
    getGrupos: async function({ commit }) {
    //   let header = { Token: data.token };
    //   let configuracion = { headers: header };
    debugger
      await axios
        .get(`grupo/list`)
        .then(function(response) {
          let d = response.data;
          commit("setGrupos", d);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    /* eslint-disable no-debugger */
  },
};