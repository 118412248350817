import axios from "axios";
import swal from "sweetalert";
// import moment from "moment";

export default {
  namespaced: true,
  state: {
    pagoOnline: false,
    tarifa:0, 
    disponibilidad: {},
    coche: [],
    extras: [],
    cliente: [],
    reserva: [],
  },
  mutations: {
    setPago(state, estado) {
      state.pagoOnline = estado;
    },
    setTarifa(state, tarifa) {
      state.tarifa = tarifa;
    },
    setDisponibilidad(state, disponibilidad) {
      state.disponibilidad = disponibilidad;
    },
    setCoche(state, coche) {
      state.coche = coche;
    },
    setExtras(state, extras) {
      state.extras = extras;
    },
    setCliente(state, cliente) {
      state.cliente = cliente;
    },
    setReserva(state, reserva) {
      state.cliente = reserva;
    },
  },
  actions: {
    /* eslint-disable no-debugger */
    tarifa({commit}, tarifa) {
      commit("setTarifa", tarifa);
    },
    pagoOnline({commit}, estado) {
      commit("setPago", estado);
    },
    guardarDisponibilidad({ commit }, disponibilidad) {
      debugger
      localStorage.setItem("datos_reserva", JSON.stringify(disponibilidad));
      commit("setDisponibilidad", disponibilidad);
    },
    guardarCoche({ commit }, coche) {
      commit("setCoche", coche);
    },
    guardarExtras({ commit }, extras) {
      commit("setExtras", extras);
    },
    guardarCliente({ dispatch }, cliente) {
      dispatch("getCliente", cliente);
    },
    getCliente: async function({ commit, dispatch }, cliente) {
      // let header = { Token: data.token };
      // let configuracion = { headers: header };
      await axios
        .get(`cliente/exist?email=${cliente.email}`)
        .then(function(response) {
          if (response.data) {
            response.data.observaciones = cliente.observaciones;
            commit("setCliente", response.data);
            console.log("Cliente existe");
          } else {
            dispatch("saveCliente", cliente);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    getReserva: async function({ commit }, data) {
      let header = { Token: data.token };
      let configuracion = { headers: header };
      let d = null;

      await axios
        .get(`reserva/query?id=${data.id}`, configuracion)
        .then(function(response) {
          d = response.data.detalles;
          commit("llenarReserva", d);
        })
        .catch(function(error) {
          swal({
            title: "Lo sentimos!",
            text: `Ha ocurrido un error de tipo ${error} al intentar obtener esta reserva. Informe al equipo de desarrollo sobre este error`,
            icon: "error",
          });
          console.log(error);
        });
    },
    saveCliente: async function({ commit }, usr) {
      // let header = { Token: data.token };
      // let configuracion = { headers: header };
      await axios
        .post("cliente/add", {
          usuario: 9,
          tipo_cliente: usr.tipo_cliente,
          nombre: usr.nombre,
          apellido: usr.apellido,
          email: usr.email,
          telefono_movil: usr.telefono,
        })
        .then(function() {
          commit("setCliente", usr);
          console.log("Cliente guardado");
        })
        .catch(function(error) {
          console.log("Error" + error);
        });
    },
    saveReserva: async function({dispatch}, data) {
      debugger
      let header = { Token: data.token };
      let configuracion = { headers: header };
      let francodepos = data.francodepos;
      let nombreTipo = '';
      francodepos.tipo == 1 ? nombreTipo = 'Franquicia' : nombreTipo = 'Depósito';
      await axios
        .post(
          "reserva/add",
          {
            usuario: 9,
            coche: data.cocheId,
            coche_marca: data.cocheMarca,
            coche_modelo: data.cocheModelo,
            coche_grupo: data.grupo,
            coche_matricula: data.cocheMatricula,
            cliente: data.clienteId,
            nombre: data.clienteName,
            apellido: data.clienteSurname,
            telefono: data.telefono,
            edad_conductor: data.edadConductor,
            email: data.email,
            tipo_francdepos: nombreTipo,
            valor_francdepos: data.francodepos.valor,
            tarifa: data.tarifa,
            total: data.total,
            fecha_entrega: data.fecha_entrega,
            fecha_recogida: data.fecha_recogida,
            hora_entrega: data.hora_entrega,
            hora_recogida: data.hora_recogida,
            lugar_entrega: data.lugar_entrega,
            lugar_recogida: data.lugar_recogida,
            extra: data.extra,
            observaciones: data.observaciones
          },
          configuracion
        )
        .then(function(res) {
          dispatch("sendEmail", {res: res,data: data});
          swal({
            title: "Buen trabajo!",
            text: `Reserva ${res.data.id} creada correctamente. 
            Recibirás un correo electrónico con la confirmación`,
            icon: "success",
          });
          
          setTimeout(function(){ window.location.href = "https://librarentacar.com/" }, 4000);
          
        })
        .catch(function(error) {
          swal({
            title: "Lo sentimos!",
            text: `Ha ocurrido un error de tipo ${error}`,
            icon: "error",
          });
        });
    },
    sendEmail: async function({commit},data) {
      // let header = { Token: data.token };
      // let configuracion = { headers: header };
           
      debugger
      await axios
        .post(
          "email/confirm",
          {
            dataReserva: data.res
          }
        )
        .then(function() {
          console.log("Email enviado")
        })
        .catch(function() {
          console.log("Error al enviar email")
        });
      commit()
    },
  },
};
