<template>
  <v-footer>
    <v-card text tile width="100%" class="text-center" color="primary">
      <!-- <v-card-text>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn class="mx-4" icon v-on="on" :to="{name: 'home'}">
              <v-icon size="24px">home</v-icon>
            </v-btn>
          </template>
          <span>Inicio</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn class="mx-4" icon v-on="on" :to="{name: 'contact'}">
              <v-icon size="24px">contact_mail</v-icon>
            </v-btn>
          </template>
          <span>Contacto</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn class="mx-4" icon v-on="on" :to="{name: 'home'}">
              <v-icon size="24px">assignment</v-icon>
            </v-btn>
          </template>
          <span>Términos y Condiciones</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn class="mx-4" icon v-on="on" :to="{name: 'home'}">
              <v-icon size="24px">https</v-icon>
            </v-btn>
          </template>
          <span>Políticas de privacidad</span>
        </v-tooltip>
      </v-card-text> -->

      <v-divider></v-divider>

      <v-card-text class="white--text">
        &copy;
        {{ new Date().getFullYear() }} — <strong>Libra Rent a Car</strong> |
        Desarrollado por
        <strong>
          <a
            href="https://dev.tedesco.es/"
            style="text-decoration: none; color: inherit;"
            >Tedesco Dev
          </a>
        </strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer'
};
</script>

<style></style>
