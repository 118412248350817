import Dialog from "@/components/Dialog.vue";
import axios from "axios";
import { mapState, mapActions } from "vuex";
import Bus from "@/EventBus";
import Img from "./Img.vue";

export default {
  name: "Cards",
  data() {
    return {
      dialog: false,
      cars: [],
      date: null,
    };
  },
  components: {
    Dialog,
    Img,
  },
  computed: {
    ...mapState("reservaNamespace", ["disponibilidad"]),
  },
  methods: {
    ...mapActions("cocheNamespace", ["guardarData"]),
    ...mapActions("reservaNamespace", ["pagoOnline"]),
    /* eslint-disable no-debugger */
    pagoTotal(plan, car, francodepos) {
      debugger
      localStorage.setItem("plan", JSON.stringify(plan));
      localStorage.setItem("date", JSON.stringify(this.date));
      localStorage.setItem("tipo", francodepos.tipo);
      localStorage.setItem("valor", francodepos.valor);
      localStorage.setItem("datos_vehiculo", JSON.stringify(car));
      this.guardarData({
        coche: car,
        date: this.date,
        plan: plan,
        francodepos: francodepos,
      });
      this.pagoOnline(true);
      Bus.$emit("update");
      this.$emit("next");
    },
    pagoOficina(plan, car, francodepos) {
      localStorage.setItem("plan", JSON.stringify(plan));
      localStorage.setItem("date", JSON.stringify(this.date));
      localStorage.setItem("datos_vehiculo", JSON.stringify(car));
      localStorage.setItem("tipo", francodepos.tipo);
      localStorage.setItem("valor", francodepos.valor);
      this.guardarData({
        coche: car,
        date: this.date,
        plan: plan,
        francodepos: francodepos,
      });
      this.pagoOnline(false);
      Bus.$emit("update");
      this.$emit("next");
    },
    dialogFn() {
      this.dialog = true;
    },
    getCars() {
      debugger
      let getLocal = JSON.parse(localStorage.getItem("datos_reserva"));
      let date1 = getLocal.date_desde.split("T")
      let date2 = getLocal.date_hasta.split("T")
      let horaRecogida = getLocal.hora_recogida
      let horaEntrega = getLocal.hora_entrega
      date1 = date1[0]
      date2 = date2[0]
      axios
        .post(`fecha-temporadas/query`, {
          date_desde: date1,
          date_hasta: date2,
          hora_entrega: horaEntrega,
          hora_recogida: horaRecogida,
        })
        .then((resp) => {
          let localcars = resp.data.data;
          this.cars = [];
          localcars.forEach((elementGrupos) => {
            this.date = elementGrupos.date
            let flat = false;
            this.cars.forEach((element) => {
              if (element.id == elementGrupos.coches.id) {
                flat = true;
              }
            });
            if (!flat) {
              this.cars.push({
                id: elementGrupos.coches.id,
                marca: elementGrupos.coches.marca,
                modelo: elementGrupos.coches.modelo,
                matricula: elementGrupos.coches.matricula,
                image: elementGrupos.imagen,
                groupId: elementGrupos.id,
                groupName: elementGrupos.nombre,
                basic: {
                  title: "Basic",
                  monto: elementGrupos.basic.monto,
                  franquicia: elementGrupos.valor_franquicia,
                  total: elementGrupos.basic.monto * this.date,
                },
                premiun: {
                  title: "Premium",
                  monto: elementGrupos.premium.monto,
                  deposito: elementGrupos.valor_deposito,
                  total: elementGrupos.premium.monto * this.date,
                },
              });
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getData() {
      console.log(this.disponibilidad);
    },
    /* eslint-disable no-debugger */
  },
  created() {
    this.getCars();
  },
};
