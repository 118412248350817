import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import Reserva from '../views/Reserva.vue'
import Login from '../components/Login'
import Prueba from '@/views/prueba.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/reserva',
    name: 'reserva',
    components: { default: Reserva, header: Header, footer: Footer }
  },
  {
    path:'/prueba',
    name:'prueba',
    component: Prueba
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

if (process.env.VUE_APP_GOOGLE_ANALYTICS) {
  Vue.use(require('vue-analytics').default, {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS,
    router,
    autoTracking: {
      page: process.env.NODE_ENV !== 'development'
    }
  })
}

export default router