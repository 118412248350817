

export default {
  namespaced: true,
  state: {
    cliente: {},
  },
  mutations: {
    setCliente(state, usr) {
      state.cliente = usr;
      console.log( state.cliente )
    },
  },
  actions: {
    guardarCliente({ commit }, usr) {
      console.log( usr )
      commit("setCliente", usr);
    },
  },
};
