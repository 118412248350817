import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import axios from 'axios';
import moment from 'moment'
import ProgressBar from 'vuejs-progress-bar'
import Vuelidate from 'vuelidate'
import FlagIcon from 'vue-flag-icon';
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.use(ProgressBar)
Vue.use(Vuelidate)
Vue.use(FlagIcon);

moment.locale('es')
const heroku = 'https://api-libra.herokuapp.com/api/'
const api_plesk = 'https://api.easyrentcar.eu/api/'
let back = window.location.host == 'localhost:8080' ? 'http://localhost:4000/api/' : api_plesk;
//let back = 'http://localhost:4000/api/';
//cambiar bor back al subir
axios.defaults.baseURL= back;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");
